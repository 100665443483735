import React from "react";
import { useNavigate } from 'react-router-dom'
import { url } from "../Constants";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import { useState } from "react";

const CourseSelection = () => {
    
    const navigate = useNavigate(); // Initialize navigate function
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleMarkComplete = async (step) => {
        
        if (isSubmitting) return; // Prevent multiple submissions
        setIsSubmitting(true);

        try {
            const response = await fetch(`${url}/user/updateCompletionSteps`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: localStorage.getItem("token"),
                },
                body: JSON.stringify({ [`step${step}Complete`]: true }),
            });

            const res_json = await response.json();

            if (res_json.success) {
                swal({
                    title: "Success!",
                    text: "Step marked as complete!",
                    icon: "success",
                    button: "Ok!",
                });
                navigate('/userdashboard');
            } else {
                swal({
                    title: "Error!",
                    text: res_json.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        } catch (error) {
            console.error("Error marking step as complete:", error);
        }

        try {
            // Assuming you're fetching or have access to user data
            const firstName = localStorage.getItem('firstName') || 'User';
            const email = localStorage.getItem('email');
            const stepCompleted = step; // Replace with the actual step number if dynamic

            // Validate required data
            if (!email || !step) {
                console.error('Email is missing');
                return;
            }

            // Construct the `userData` object
            const userData = {
                stepCompleted: step,
                firstName,
                email,
            };

            const response = await fetch(`${url}/user/send-completion-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem('token'), // Assuming token is stored in localStorage
                },
                body: JSON.stringify(userData),
            });

            const res_json = await response.json();

            if (res_json.success) {
                //console.log('Email sent successfully');
            } else {
                console.error('Failed to send email', res_json.message);
            }
        } catch (error) {
            console.error('Error sending email', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <div className="body-content no-filterpanel">
                <div className="body-panel d-flex justify-content-between">
                    <div className="body-container width-100">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="page-content">
                                        <h1 className="mb-3">Shortlisting</h1>
                                        {/* <p>
                                    Unlock your ideal student living experience with our cutting-edge accommodation portal, offering personalized solutions, extensive options, and a seamless platform that revolutionizes the way you find and secure your perfect home away from home.
                                    </p>
                                    <a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                                    </div>
                                    <div>
                                        <Button
                                            variant="primary-regular blinking-btn me-2 float-end"
                                            onClick={() => handleMarkComplete(5)} // Replace 5 with the appropriate step number
                                            className="ms-2"
                                        >
                                            Mark Step as Complete
                                        </Button>
                                        <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                            <i className='fa fa-home'></i> Back to Dashboard
                                        </button>
                                        <button onClick={() => { navigate('/shortlisting-user/' + localStorage.getItem('_id')) }} className="widget-cta-page-nopos-noresponsive float-end mb-3 me-2">
                                            View Shortlistings
                                        </button>
                                    </div>
                                    {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <iframe src="https://risehighereducation.com/wp-content/uploads/drive/course-selection/index.html" height="450" width="100%"></iframe>
                                <div className="course-alt">
                                    <div className="course-alt-or">OR</div>
                                    If you <span className="text-black">don't see your ideal program</span>, let our counseling team <span className="text-black">create shortlisting</span> for you. <span className="text-black">Complete</span> our application <span className="text-black">profile form</span>, and <span className="text-black">receive</span> a personalized <span className="text-black">program list</span> within <span className="text-black">48 hours</span> via email
                                    <button onClick={() => { navigate('/applicationprofile') }} className="float-end btn-profileab">Application Profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={()=>{navigate('/documents')}} className="widget-cta-page float-end mx-4">Get Documents Checklist</button> */}
                <div className="footer-links">
                    <button
                        type="button"
                        className="footer-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#disclaimerModal"
                    >
                        Disclaimer
                    </button>
                    <button
                        type="button"
                        className="footer-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#TnCModal"
                    >
                        Terms &amp; Conditions
                    </button>
                    <div
                        className="modal fade"
                        id="disclaimerModal"
                        tabIndex={-1}
                        aria-labelledby="disclaimerModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="disclaimerModal">
                                        Disclaimer
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id="TnCModal"
                        tabIndex={-1}
                        aria-labelledby="TnCModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="TnCModal">
                                        Terms &amp; Conditions
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <p>
                                        If you have any questions or concerns regarding the Portal, please
                                        contact our support team at{" "}
                                        <a href="mailto:contact@risehighereducation.com">
                                            contact@risehighereducation.com
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseSelection;