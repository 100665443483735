import React, { useEffect, useState } from 'react'
import { useAudio } from '../../Context/AudioContext'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'
import { Typography, LinearProgress } from '@mui/material'
import swal from 'sweetalert'
import { url } from '../Constants'

const CountryCard = (props) => {
  const { thumbData, setThumbData, maxLimit } = useAudio()
  const [savedDestinations, setSavedDestinations] = useState([])

  // Fetch saved study destinations
  const getSavedStudyDestinations = async () => {
    try {
      const response = await fetch(`${url}/auth/verifyuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        },
      })

      const res_json = await response.json()
      const fetchedStudyDest = res_json?.data?.studyDest ?? []
      const studyDestArray = Array.isArray(fetchedStudyDest)
        ? fetchedStudyDest
        : [fetchedStudyDest]

      setSavedDestinations(studyDestArray)
    } catch (error) {
      console.error('Error fetching study destinations:', error)
    }
  }

  useEffect(() => {
    getSavedStudyDestinations()
  }, [])

  const handleChnage = (e) => {
    let checked = e.target.checked
    if (checked && thumbData?.length < maxLimit) {
      setThumbData((prev) => [...prev, props.data])
    } else {
      if (checked) {
        swal({
          title: 'Error!',
          text: 'You can only select 3 countries!',
          icon: 'error',
          button: 'Ok',
        })
      }
      setThumbData((prev) =>
        prev.filter((item) => item.name !== props.data.name),
      )
    }
  }

  const handleSaveStudyDest = async (newStudyDestArray) => {
    try {
      const response = await fetch(`${url}/user/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'), // User token
        },
        body: JSON.stringify({
          feild: 'userInfo', // Make sure field is correct ('feild' or 'field')
          data: { studyDest: newStudyDestArray }, // Updated study destinations
        }),
      })

      if (!response.ok) {
        const errorMessage = await response.text()
        swal({
          title: 'Error!',
          text: `Failed with status: ${response.status}`,
          icon: 'error',
        })
        return
      }

      const result = await response.json()

      if (result.success) {
        swal({
          title: 'Saved!',
          text: 'Study destination updated successfully.',
          icon: 'success',
        })

        // Refresh study destinations after saving
        await getSavedStudyDestinations()
      } else {
        swal({
          title: 'Error!',
          text: result.message || 'Failed to update the destination.',
          icon: 'error',
        })
      }
    } catch (error) {
      swal({
        title: 'Error!',
        text: 'Failed to update the destination. Please try again.',
        icon: 'error',
      })
      console.error('Error saving study destination:', error)
    }
  }

  // Check if the current country is already saved as a study destination
  const isCountrySaved = savedDestinations.some(
    (dest) =>
      dest.trim().toLowerCase() === props.data.name.trim().toLowerCase()
  )

  return (
    <div className="timeline-carousel__item">
      <div className="timeline-carousel__image">
        <div
          className="media-wrapper media-wrapper--overlay"
          style={{
            background: `url(${props.data.CountryFlag}) center center`,
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      <div className="timeline-carousel__item-inner">
        <span className="year">{props.data.name}</span>
        {isCountrySaved ? (
          <span className="badge bg-success">
            Saved Study Destination
          </span>
        ) : (
          <>
          <span className='text-center d-block'>
          <button
            onClick={() => handleSaveStudyDest([...savedDestinations, props.data.name])}
            className="btn btn-link"
            disabled={savedDestinations.length >= 3} // Disable button if max destinations selected
          >
            {savedDestinations.length >= 3 ? 'Max Study Destinations Selected' : 'Save Study Destination'}
          </button>
          </span>
          </>
        )}
        <span className="country-info">
          <ul>
            <li>
              <strong>{props.data.tagLine}</strong>
            </li>
            <li>
              <i className="fas fa-money-bill-wave fa-xl" /> {props.data.currency}
            </li>
            <li>
            <p>
              {props.data.name === "India" ? (
                <>
                <strong>1113</strong> (<strong>56</strong> central, <strong>478</strong> state, <strong>124</strong> deemed, <strong>455 </strong>private, and <strong>159</strong> institutions of national importance in India).
                </>
              ) : (
                <>
                {Number(props.data?.public_universities) + Number(props.data?.private_universities)} ( {props.data?.public_universities} Public &amp;{' '}
                {props.data?.private_universities} Private Universities)
                </>
              )}
              </p>
            </li>
            <li>
              {props.data.name === "United States of America" ? (
                <>
                  13 universities consistently ranked among the world's top 20 prestigious league
                </>
              ) : (
                <>
                  <strong>{props.data.rankedt100}</strong> universities ranked in top 100 and{' '}
                  <strong>{props.data.rankedt500}</strong> universities ranked in top 500.
                </>
              )}
            </li>
            <li>
              <strong>International Students:</strong>{' '}
              {props.data.international_students}
            </li>
            <li>
              <strong>Graduate Employment Rate: </strong>{props.data.employment_rate}
            </li>
            {/* <li> */}
            {/* <Box sx={{ position: 'relative', width: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <LinearProgress
                  variant="determinate"
                  value={props.data.employment_rate}
                  sx={{
                    height: 10,
                    borderRadius: 0,
                    flex: 1,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#dc7215', // Customize bar color
                    },
                  }}
                />
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize={11}
                    sx={{ marginLeft: 1 }}
                  >
                    {`${Math.round(props.data.employment_rate)}%`}
                  </Typography>
              </Box> */}
            {/* </li> */}
            <span className="country-selection">
              <input
                type="checkbox"
                defaultValue=""
                checked={thumbData?.includes(props.data)}
                onChange={handleChnage}
              />
            </span>
          </ul>
        </span>
      </div>
    </div>
  )
}

export default CountryCard
