import React, { useEffect, useState } from "react";
import readXlsxFile from "read-excel-file";
import API from "../../Api/Api";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const ShortlistingAdmin = () => {
  const [readData, setReadData] = useState([]);
  const [metaData, setMetaData] = useState({
    name: "",
  });
  const params = useParams();
  const [filesAll, setFilesAll] = useState([]);
  const navigate = useNavigate();
  const handleRead = (e) => {
    setMetaData({ ...metaData, name: e[0].name });
    readXlsxFile(e[0]).then((rows) => {
      setReadData(rows);
    });
  };

  const handleUpload = async () => {
    try {
      const isDuplicate = filesAll.some((file) => file.name === metaData.name);
    
    if (isDuplicate) {
      swal({
        title: "Duplicate File",
        text: "A file with this name already exists. Please rename the file and try again.",
        icon: "warning",
        button: "Ok!",
      });
      return; // Exit the function if duplicate is found
    }
    
      await API("post", "/shortlisting/", {
        ...metaData,
        data: readData,
        user: params.id,
      });
      swal({
        title: "Success!",
        text: "Data Uploaded",
        icon: "success",
        button: "Ok!",
      });
      getAllFiles();
      //   window.location.reload();
    } catch (error) {}
  };

  const getAllFiles = async () => {
    try {
      const response = await API("get", `/shortlisting/${params.id}`);
      setFilesAll(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllFiles();
  }, []);

  const handleDeleteFile = async (id) => {
    try {
      const response = await API("delete", `/shortlisting/${id}`);
      swal({
        title: "Success!",
        text: response?.data?.message,
        icon: "success",
        button: "Ok!",
      });
      getAllFiles();
    } catch (error) {}
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };
  
  return (
    <div
    className="container admin-createpage"
  >
          <div className="main-heading">Add Shortlisting Files (.xlsx)</div>
          <br />
          <input
            type="file"
            className="form-control"
            id="File"
            onChange={(e) => handleRead(e.target.files)}
          />
          <br />
          <input
            type="text"
            value={metaData?.name}
            className="form-control"
            placeholder="Enter File Name"
            onChange={(e) => setMetaData({ ...metaData, name: e.target.value })}
          />

          <br />
          <div className="d-flex">
            <div>
          <button
            className="btn btn-primary-regular"
            onClick={handleUpload}
          >
            Upload
          </button>
          </div>
          <div>
          <button
                        type="submit"
                        onClick={backButton}
                        className="btn btn-secondary-regular"
                      >
                        Back
                      </button>
                      </div>
                      </div>
          <div className="main-heading">Uploaded Files</div>
          <div className="table-responsive">
            <table className="table admin-table">
              <thead>
                <tr className="table-dark">
                  <th scope="col">File Name</th>
                  <th scope="col">Rows</th>
                  <th scope="col"> Added on </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {filesAll.map((item) => {
                  return (
                    <tr key={item?._id}>
                      <td>{item?.name}</td>
                      <td>{item?.data.length}</td>
                      <td>
                        {moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td className="d-flex">
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this imaginary file!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((res) => {
                              if (res) {
                                handleDeleteFile(item?._id);
                              }
                            })
                          }
                        >
                          Delete
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                        style={{ width: "100px" }}
                          className="btn btn-primary"
                          onClick={() =>
                            navigate('/shortlisting-user/' + params.id)
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
  );
};

export default ShortlistingAdmin;
