import React from 'react';
import { useNavigate } from 'react-router-dom'

const SimCard = () => {

    const navigate = useNavigate();

    return (
        <>
                <div>
            <div className="body-content no-filterpanel">
            <div className="body-panel d-flex justify-content-between">
                <div className="body-container width-100">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between mt-3">
                                <div className="page-content">
                                    <h1 className="mb-3">SIM Card</h1>
                                    <p>
                                    Get One SIM and keep your parents worry-free during your travel and transit. Save up to 85% on international calling bills while staying in touch effortlessly.
                                    </p>
                                    {/* <a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                                </div>
                                <div>
                                    <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button>
                                </div>
                                {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col bg-white">
                        <table className="table sim-card">
                            <tbody>
                                <tr>
                                <td><img src="https://www.onesimcard.com/images/preview/onesimcard-universal-simL.jpg" /></td>
                                <td>
                                    <strong>OneSimCard Universal</strong>
                                    <ul>
                                    <li>3-in-1 SIM size - Mini, Micro and Nano Sizes</li>
                                    <li>Includes 2 numbers: Europe & USA, Canada, UK or Australia</li>
                                    <li>4G services in 50+ countries; 5G available</li>
                                    <li>Direct dial in 50+ countries and callback dial in other countries.</li>
                                    <li>Add numbers in 60+ other countries</li>
                                    <li>Coverage in over 200 countries</li>
                                    <li>Discount packages available</li>
                                    <li>Voice mail service with free delivery to email</li>
                                    <li>Free incoming calls received on EU number in 120+ countries. Outgoing calls from 25c/min</li>
                                    <li>Live Technical Support</li>
                                    <li>*Free shipping to the U.S. on orders over $50</li>
                                    <li>*Free shipping to Canada on orders over $100</li>
                                    <li>International shipping from the U.S. from only $4.99</li>
                                    </ul>
                                    <a href='https://www.onesimcard.com/international-sim-card/universal/?70481' target='_blank' className='btn-secondary px-2 py-1'>Buy Now</a>
                                </td>
                                </tr>
                                <tr>
                                <td><img src="https://www.onesimcard.com/images/preview/onesimcard_expedition-international_sim_cardL.jpg" /></td>
                                <td>
                                    <strong>OneSimCard Expedition</strong>
                                    <ul>
                                    <li>Universal SIM size SIM card - Mini, Micro and Nano Size</li>
                                    <li>Fits all GSM phones including iPhones</li>
                                    <li>Coverage in 200+ countries</li>
                                    <li>Internet Data Coverage in over 170 countries; 4G/5G available</li>
                                    <li>Mobile data service from 2c/MB</li>
                                    <li>Bi-Weekly/Monthly packages available</li>
                                    <li>Perfect for iPads, tablets, computers and MiFi hotspot devices</li>
                                    <li>Includes 2 numbers: Europe & USA, Canada, UK or Australia</li>
                                    <li>Add numbers in 60+ other countries</li>
                                    <li>Voice mail service with free delivery to email</li>
                                    <li>Free incoming calls received on EU number in over 130 countries </li>
                                    <li>Live Technical Support</li>
                                    <li>*Free shipping to the U.S. on orders over $50</li>
                                    <li>*Free shipping to Canada on orders over $100</li>
                                    <li>International shipping from the U.S. from only $4.99</li>
                                    </ul>
                                    <a href='https://www.onesimcard.com/international-sim-card/onesimcard-data-and-roam/?70481' target='_blank' className='btn-secondary px-2 py-1'>Buy Now</a>
                                </td>
                                </tr>
                                <tr>
                                <td><img src="https://www.onesimcard.com/images/preview/eSIM-World-5G-webL.jpg" /></td>
                                <td>
                                    <strong>OneSimCard eSIM World</strong>
                                    <ul>
                                    <li>Virtual second SIM card (eSIM)</li>
                                    <li>Make your compatible unlocked phone a dual SIM phone</li>
                                    <li>Works with many Apple iPhone, Google Pixel, Samsung and other devices</li>
                                    <li>Coverage in 150+ countries with free incoming calls received on EU number in most of them</li>
                                    <li>Includes 1 number: Europe. Add extra numbers in 60+ other countries</li>
                                    <li>Mobile data service from 1c/MB; 4G/5G available</li>
                                    <li>Data, Voice and Text. Extra savings available with OneSIM VoIP app</li>
                                    <li>Bi-Weekly/Monthly data discount plans</li>
                                    <li>Perfect for travelers worldwide</li>
                                    <li>Live Customer Support</li>
                                    <li>No shipping. Download eSIM and go.</li>
                                    </ul>
                                    <a href='https://www.onesimcard.com/international-sim-card/onesimcard-esim/?70481' target='_blank' className='btn-secondary px-2 py-1'>Buy Now</a>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={()=>{navigate('/recruitment')}} className="widget-cta-page float-end mx-4">Explore Job Options</button>
            <div className="footer-links p-5">
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#disclaimerModal"
                >
                    Disclaimer
                </button>
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#TnCModal"
                >
                    Terms &amp; Conditions
                </button>
                <div
                    className="modal fade"
                    id="disclaimerModal"
                    tabIndex={-1}
                    aria-labelledby="disclaimerModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="disclaimerModal">
                                    Disclaimer
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="TnCModal"
                    tabIndex={-1}
                    aria-labelledby="TnCModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="TnCModal">
                                    Terms &amp; Conditions
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    If you have any questions or concerns regarding the Portal, please
                                    contact our support team at{" "}
                                    <a href="mailto:contact@risehighereducation.com">
                                        contact@risehighereducation.com
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
        
export default SimCard;