import React, { useState } from 'react';
import AccommodationData from './accommodationData';
import { url } from '../Constants';
import swal from 'sweetalert';
import { validateAccommodation } from '../../utils/validation';

const Accommodation = () => {
    const [listParams, setListParams] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        msg: "",
    });

    const [errors, setErrors] = useState({});

    const onChange = (event) => {
        setListParams({ ...listParams, [event.target.name]: event.target.value, });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[event.target.name];
            return newErrors;
        });
    };

    const sendMsg = async () => {
        const { isValid, errors } = validateAccommodation(listParams);

        if (!isValid) {
            setErrors(errors); // Set validation errors to state
            return; // Exit the function if form is not valid
        }

        const response = await fetch(`${url}/user/files/publicemail`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
            },
            mode: 'cors',
            referrerPolicy: "origin-when-cross-origin",
            body: JSON.stringify({
                firstName: listParams.firstName,
                lastName: listParams.lastName,
                email: listParams.email,
                phone: listParams.phone,
                msg: listParams.msg,
                from: "Public Accomodation"
            })
        });

        const data = await response.json();

        if (data.success) {
            swal({
                title: "Success!",
                text: "Message Sent!",
                icon: "success",
                button: "Ok!",
            })
            setListParams({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                msg: '',
              });
        } else {
            swal({
                title: "Error!",
                text: data.message,
                icon: "error",
                button: "Ok!",
            });
        }
    };
    return (
        <div>
            <div className="body-content no-filterpanel public-view">
                <div className="body-panel d-flex justify-content-between">
                    <div className="body-container width-100">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="page-content">
                                        <h1 className="mb-3">Accommodation</h1>
                                        <p>
                                            Unlock your ideal student living experience with our cutting-edge accommodation portal, offering personalized solutions, extensive options, and a seamless platform that revolutionizes the way you find and secure your perfect home away from home.
                                        </p>
                                        <a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                            Download Transcript
                                        </a>
                                    </div>
                                    <div className="video-container ms-5">
                                        {/* <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button> */}
                                        <iframe
                                            src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen=""
                                            width={500}
                                            height={250}
                                            frameBorder={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3">
                                <AccommodationData />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col mx-1 my-5 w-50'>
                                <p className='fw-bold'>If you can't find accommodation, inform us. We'll search with our other partners inventory to find your ideal place.</p>
                                <div class="row">
                                    <div class="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                name="firstName"
                                                onChange={onChange}
                                                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={listParams.firstName}
                                                placeholder="First Name"
                                            />
                                            <label for="floatingInput">
                                                First Name<span className="required">*</span>
                                            </label>
                                            {errors.firstName && (
                                                <span className="required">{errors.firstName}</span> // Display error message
                                            )}
                                        </div>
                                    </div><div class="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                name="lastName"
                                                onChange={onChange}
                                                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                                type="text"
                                                value={listParams.lastName}
                                                placeholder="Last Name"
                                            />
                                            <label for="floatingInput">
                                                Last Name<span className="required">*</span>
                                            </label>
                                            {errors.lastName && (
                                                <span className="required">{errors.lastName}</span> // Display error message
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        name="email"
                                        onChange={onChange}
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        type="email"
                                        value={listParams.email}
                                        placeholder="Email"
                                    />
                                    <label for="floatingInput">
                                        Email<span className="required">*</span>
                                    </label>
                                    {errors.email && (
                                        <span className="required">{errors.email}</span> // Display error message
                                    )}
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        name="phone"
                                        onChange={onChange}
                                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                        type="text"
                                        maxLength={15}
                                        value={listParams.phone}
                                        placeholder="Phone Number"
                                    />
                                    <label for="floatingInput">
                                        Phone Number (include county code)<span className="required">*</span>
                                    </label>
                                    {errors.phone && (
                                        <span className="required">{errors.phone}</span> // Display error message
                                    )}
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea
                                        type="text"
                                        className={`form-control ${errors.msg ? 'is-invalid' : ''}`}
                                        aria-describedby="emailHelp"
                                        placeholder="Enter your message here"
                                        rows={5}
                                        value={listParams.msg}
                                        onChange={onChange}
                                        name="msg"
                                    />
                                    <label for="floatingInput">
                                        Please let us know your requirements
                                        <span className="required">*</span>
                                    </label>
                                    {errors.msg && (
                                        <span className="required">{errors.msg}</span> // Display error message
                                    )}
                                </div>
                                <button type="submit" className="btn btn-primary-regular" onClick={sendMsg}>
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={()=>{navigate('/flights')}} className="widget-cta-page mt-5 float-end mx-4">View Flights Options</button> */}
                <div className="footer-links">
                    <button
                        type="button"
                        className="footer-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#disclaimerModal"
                    >
                        Disclaimer
                    </button>
                    <button
                        type="button"
                        className="footer-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#TnCModal"
                    >
                        Terms &amp; Conditions
                    </button>
                    <div
                        className="modal fade"
                        id="disclaimerModal"
                        tabIndex={-1}
                        aria-labelledby="disclaimerModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="disclaimerModal">
                                        Disclaimer
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <p>The information provided on our accommodation search portal is for general informational
                                        purposes only. While we strive to ensure the accuracy and reliability of the
                                        information, we make no representations or warranties of any kind, express or implied,
                                        about the completeness, accuracy, reliability, suitability, or availability of the
                                        portal or the information, products, services, or related graphics contained on the
                                        portal.
                                        Any reliance you place on such information is strictly at your own risk. We disclaim any
                                        responsibility for any loss or damage, including without limitation, indirect or
                                        consequential loss or damage, or any loss or damage whatsoever arising from loss of data
                                        or profits arising out of or in connection with the use of this portal.
                                    </p>
                                    <p>
                                        Through this portal, you may be able to link to other websites that are not under our
                                        control. We have no control over the nature, content, and availability of those sites.
                                        The inclusion of any links does not necessarily imply a recommendation or endorse the
                                        views expressed within them.
                                        Every effort is made to keep the portal up and running smoothly. However, we take no
                                        responsibility for, and will not be liable for, the portal being temporarily unavailable
                                        due to technical issues beyond our control.
                                    </p>
                                    <p>
                                        It is important to exercise due diligence, verify the information provided, and make
                                        independent assessments and decisions regarding accommodations. We recommend contacting
                                        the respective partner or vendor directly to confirm availability, terms, and policies
                                        before making any bookings or commitments.
                                        In no event will we be liable for any loss or damage, including without limitation,
                                        indirect or consequential loss or damage, or any loss or damage whatsoever arising from
                                        your use of this portal.
                                    </p>
                                    <p>
                                        By using our accommodation search portal, you acknowledge that you have read,
                                        understood, and agreed to this disclaimer. If you do not agree to any part of this
                                        disclaimer, please refrain from using the portal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id="TnCModal"
                        tabIndex={-1}
                        aria-labelledby="TnCModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="TnCModal">
                                        Terms &amp; Conditions
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <p>
                                        By using our accommodation search portal, you acknowledge that the availability of accommodations is subject to availability and we do not have control over the listings. You understand that the listings are provided directly by our trusted partners and vendors, and any bookings or agreements are between you and the respective partner or vendor. Please review and confirm the details with the partner or vendor before making any bookings or commitments. If you do not agree to any part of these terms, please refrain from using the portal.
                                    </p>
                                    <ol>
                                        <li>
                                            <strong>Use of the Portal:</strong> By using our accommodation search portal, you agree to comply with these terms and conditions and any applicable laws and regulations.
                                        </li>
                                        <li>
                                            <strong>Availability of Accommodation:</strong> The supply of accommodation listed on our portal is subject to availability, and we do not have control over the availability of specific listings. We advise users to exercise due diligence and confirm the availability before making any bookings or commitments.
                                        </li>
                                        <li>
                                            <strong>Third-Party Partners and Vendors:</strong> The accommodation listings on our portal are provided by our trusted partners and vendors. While we strive to maintain the accuracy and reliability of the information, we do not guarantee the availability, quality, or suitability of the accommodations listed. Any bookings or agreements made are directly between the user and the respective partner or vendor.
                                        </li>
                                        <li>
                                            <strong>User Responsibilities:</strong> Users are responsible for conducting their own research, verifying the details, and making informed decisions regarding accommodation bookings. We recommend users to review the terms, policies, and availability with the partner or vendor before finalizing any bookings.
                                        </li>
                                        <li>
                                            <strong>Disclaimer of Liability:</strong> We shall not be held liable for any damages, losses, or liabilities arising from the use of the portal, including but not limited to any errors, omissions, inaccuracies, or unavailability of accommodations listed. Users are solely responsible for their interactions, transactions, and experiences with the partners or vendors.
                                        </li>
                                        <li>
                                            <strong>Modification or Termination:</strong> We reserve the right to modify, suspend, or terminate the portal or any part thereof, at any time and without prior notice. We may also update or revise these terms and conditions, and your continued use of the portal after such changes constitutes your acceptance of the updated terms.
                                        </li>
                                        <li>
                                            <strong>Governing Law:</strong> These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.
                                        </li>
                                    </ol>
                                    <p>
                                        These terms and conditions may be subject to change without prior
                                        notice. It is your responsibility to review them periodically for
                                        any updates.
                                    </p>
                                    <p>
                                        If you have any questions or concerns regarding the Portal, please
                                        contact our support team at{" "}
                                        <a href="mailto:contact@risehighereducation.com">
                                            contact@risehighereducation.com
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accommodation;