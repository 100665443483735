import React, { useEffect, useState } from "react";
import Countrylist from "../Assets/Data/countries.json";
import { url } from "../Constants";
import FileCard from "./FileCard";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const FileUpload = () => {
  const [listParams, setListParams] = useState({
    country: localStorage.getItem("destCountry") || "",
    program: "",
    msg: "",
  });

  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [mappedData, setMappedData] = useState([]);
  const [application] = useState(
    sessionStorage.getItem("applicationId")
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const resetForm = () => {
    setListParams({
      country: "",
      program: "",
      msg: "",
    });
    setList([]); // Reset list data
    setMappedData([]); // Reset mapped data
    setIsDisabled(true); // Disable the submit button
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    // Reset state if the user selects "Select Country" or "Select Program"
    if (value === "") {
      resetForm();
    } else {
      setListParams({ ...listParams, [name]: value });
    }
  };

  const fetchDocumentList = async () => {
    const response = await fetch(`${url}/user/files/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      body: JSON.stringify({
        country: listParams.country,
        program: listParams.program,
      }),
    });

    const data = await response.json();
    await setList(data.data);
  };

  const getUploadedFiles = async () => {
    const response = await fetch(`${url}/user/files/`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
    });

    const data = await response.json();
    await setUploadedFiles(data.data);
  };

  const dataHandler = async () => {
    await fetchDocumentList();
    await getUploadedFiles();
  };

  const sendMsg = async () => {
    const response = await fetch(`${url}/user/files/msg`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      body: JSON.stringify({
        msg: listParams.msg,
        from: "File Upload",
      }),
    });

    const data = await response.json();

    if (data.success) {
      swal({
        title: "Success!",
        text: "Message Send!",
        icon: "success",
        button: "Ok!",
      });
    } else {
      swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        button: "Ok!",
      });
    }

    setListParams({ ...listParams, msg: "" });
  };

  const submitApp = async () => {
    sessionStorage.removeItem("applicationId");
    localStorage.removeItem("destCountry");

    const response = await fetch(`${url}/application/submit`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      body: JSON.stringify({ applicationId: application }),
    });

    const res_json = await response.json();

    if (res_json.success) {
      swal({
        title: "Success",
        text: "Application Submitted",
        icon: "success",
        button: "Ok",
      });
      navigate("/applicationdashboard");
    } else {
      swal({
        title: "Error",
        text: res_json.message,
        icon: "error",
        button: "Ok",
      });
    }
  };

  const validate = () => {
    if (mappedData.length === 0) {
      setIsDisabled(true);
      return;
    }

    for (let index = 0; index < mappedData.length; index++) {
      const element = mappedData[index];

      if (element.required === true && element.files.length === 0) {
        setIsDisabled(true);
        return;
      }
    }

    setIsDisabled(false);
  };

  const handleMarkComplete = async (step) => {
    try {
      //console.log(`Marking step ${step} as complete`);
      const response = await fetch(`${url}/user/updateCompletionSteps`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({ [`step${step}Complete`]: true }),
      });

      const res_json = await response.json();

      //console.log("API Response:", res_json);

      if (res_json.success) {
        swal({
          title: "Success!",
          text: "Step marked as complete!",
          icon: "success",
          button: "Ok!",
        });
        navigate("/userdashboard");
        //getData(); // Refresh the application data
      } else {
        swal({
          title: "Error!",
          text: res_json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (error) {
      console.error("Error marking step as complete:", error);
    }

    try {
      // Assuming you're fetching or have access to user data
      const firstName = localStorage.getItem('firstName') || 'User';
      const email = localStorage.getItem('email');
      const stepCompleted = step; // Replace with the actual step number if dynamic
    
      // Validate required data
      if (!email) {
        console.error('Email is missing');
        return;
      }
    
      // Construct the `userData` object
      const userData = {
        stepCompleted,
        firstName,
        email,
      };
    
      const response = await fetch(`${url}/user/send-completion-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'), // Assuming token is stored in localStorage
        },
        body: JSON.stringify(userData),
      });
    
      const res_json = await response.json();
    
      if (res_json.success) {
        //console.log('Email sent successfully');
      } else {
        console.error('Failed to send email', res_json.message);
      }
    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  useEffect(() => {
    // Fetch documents list when country and program change
    if (listParams.country && listParams.program) {
      dataHandler();
    }
  }, [listParams.country, listParams.program]);

  useEffect(() => {
    const data = list;
    data.forEach((element, index, arr) => {
      arr[index].files = uploadedFiles[arr[index].id] || [];
    });
    setMappedData(data);
    // console.log(data);
  }, [uploadedFiles]);

  useEffect(() => {
    validate();
  }, [mappedData]);

  useEffect(() => {
    const storedDestination = JSON.parse(localStorage.getItem("destination") ?? "[]");
    const storedLevel = localStorage.getItem("level");

    setListParams((prevParams) => ({
      ...prevParams,
      country: storedDestination.length > 0 ? storedDestination[0] : prevParams.country,
      program: storedLevel?.toLowerCase()?.includes("ug")
        ? "UG"
        : storedLevel?.toLowerCase()?.includes("pg")
        ? "PG"
        : prevParams.program,
    }));
  }, []);

  useEffect(() => {
    if (listParams.country && listParams.program) {
      dataHandler();
    }
  }, [listParams.country, listParams.program]);

  return (
    <>
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          <div className="body-container width-100">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between mt-3">
                  <div className="page-content">
                    <h1 className="mb-3">Documents Checklist</h1>
                    {application !== undefined && application !== null
                      ? `Application id : ${application}`
                      : ""}
                    <p>
                      <strong>Your One-Stop Document Guide:</strong> Guidelines
                      and sample formats for Every Country and Educational Level
                    </p>
                    <p>
                      Upload your documents for verification, readiness testing,
                      expert reviews, and improvement.
                    </p>
                    {/*<a href="#"  className="link-transcript">Download Transcript</a> */}
                  </div>
                  <div className="">
                    {/* onClick={() => { navigate('/userdashboard') }} */}
                    <Button
                      variant="primary-regular blinking-btn me-2"
                      onClick={() => handleMarkComplete(6)} // Replace 5 with the appropriate step number
                      className="ms-2"
                    >
                      Mark Step as Complete
                    </Button>
                    <button
                      onClick={() => {
                        navigate("/userdashboard");
                      }}
                      className="widget-cta-page-nopos float-start margin-b3"
                    >
                      <i className="fa fa-home"></i> Back to Dashboard
                    </button>
                  </div>
                  {/* <div className="video-container ms-5">
                                        <iframe width="500" height="250" src="https://www.youtube.com/embed/FVG3VIn-1x4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="page-filter">
              <div className="row">
                <div className="col">
                  <label htmlFor="inputState" className="form-label">
                    Country<span className="required">*</span>
                  </label>
                  <select
                    list="CountryList"
                    value={listParams.country}
                    onChange={onChange}
                    placeholder="choose.."
                    className="form-select"
                    name="country"
                  >
                    <option value={""}>Select Country</option>
                    {Countrylist.map((op, i) => (
                      <option value={op.name}>{op.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="inputState" className="form-label">
                    Program<span className="required">*</span>
                  </label>
                  <select
                    name="program"
                    value={listParams.program}
                    onChange={onChange}
                    className="form-select"
                    placeholder="Select Program"
                  >
                    <option value="">Select Program</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="file-list mt-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Document Checklist</th>
                    <th>Guides</th>
                    <th>Upload File</th>
                  </tr>
                </thead>
                <tbody>
                  {mappedData.map((obj, index) => {
                    return (
                      <FileCard
                        data={obj}
                        index={index}
                        dataHandler={dataHandler}
                      />
                    );
                  })}
                  {mappedData.length < 1 ? (
                    <p className="mx-2 my-2">No checklist found</p>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
            {mappedData.length > 0 ? (
              <div className="col mx-1 my-5 w-50">
                <p className="fw-bold">
                  If you don't find the category of your document, please let us
                  know{" "}
                </p>
                <textarea
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter your message here"
                  rows={3}
                  value={listParams.msg}
                  onChange={onChange}
                  name="msg"
                />
                <button
                  type="submit"
                  className="btn btn-primary my-2 w-25"
                  disabled={listParams.msg < 5}
                  onClick={sendMsg}
                >
                  Send
                </button>
              </div>
            ) : (
              ""
            )}

            {application !== undefined && application !== null ? (
              <button
                onClick={submitApp}
                className="widget-cta-page float-start mx-4"
                disabled={isDisabled}
              >
                Submit Application
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => {
                navigate("/applicationdashboard");
              }}
              className="widget-cta-page float-end mx-4"
            >
              View Application Dashboard
            </button>
          </div>
        </div>
      </div>
      <div className="footer-links pt-4">
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#disclaimerModal"
        >
          Disclaimer
        </button>
        <button
          type="button"
          className="footer-btn"
          data-bs-toggle="modal"
          data-bs-target="#TnCModal"
        >
          Terms &amp; Conditions
        </button>
        <div
          className="modal fade"
          id="disclaimerModal"
          tabIndex={-1}
          aria-labelledby="disclaimerModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="disclaimerModal">
                  Disclaimer
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  <strong>Generalized Checklist:</strong> Rise Higher Education
                  provides a generalized documents checklist based on extensive
                  research and experience. However, each university or college
                  may have specific document requirements that can vary. Rise
                  Higher Education provides guidance on application document
                  preparation based on our understanding of common university
                  and college requirements. However, the acceptance or rejection
                  of these documents is subject to the specific protocols and
                  requirements set by individual universities and colleges.
                </p>
                <p>
                  <strong>Personalized Guidance:</strong> Our application team
                  will assist students in recognizing and preparing application
                  documents tailored to their chosen universities, colleges,
                  courses, and individual profiles.
                </p>
                <p>
                  <strong>Client Responsibility:</strong> It is the client's
                  responsibility to provide accurate and complete information to
                  our application team to ensure that the personalized documents
                  meet the specific requirements of their desired institutions.
                  Rise Higher Education provides guidance on application
                  document preparation based on our understanding of common
                  university and college requirements. However, the acceptance
                  or rejection of these documents is subject to the specific
                  protocols and requirements set by individual universities and
                  colleges.
                </p>
                <p>
                  <strong>Document Verification:</strong> Rise Higher Education
                  will review and verify the documents submitted by the client
                  to the best of our ability. However, the final responsibility
                  for document accuracy and compliance with university
                  requirements rests with the client. We emphasize the
                  importance of providing accurate, legible, and verifiable
                  documents. It is the student's responsibility to ensure that
                  the documents they submit meet the standards and requirements
                  of the institutions they are applying to.
                </p>
                <p>
                  <strong>Application Success:</strong> While we strive to
                  assist clients in preparing strong applications, admission
                  decisions ultimately depend on various factors, including
                  university policies and requirements, which are beyond our
                  control.
                </p>
                <p>
                  <strong>Non-Disclosure Pledge:</strong> Rise Higher Education
                  pledges to ensure the non-disclosure and non-misuse of client
                  documents and information without their consent. Rise Higher
                  Education is committed to maintaining the confidentiality and
                  proper use of the documents submitted by students. We pledge
                  to prevent the disclosure and misuse of documents without the
                  student's consent.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="TnCModal"
          tabIndex={-1}
          aria-labelledby="TnCModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="TnCModal">
                  Terms &amp; Conditions
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  <strong>Documents Checklist Service:</strong> Rise Higher
                  Education offers a Documents Checklist service to guide
                  clients in preparing essential application documents for their
                  chosen universities or colleges.
                </p>
                <p>
                  <strong>Generalized Checklist:</strong> The provided documents
                  checklist is based on industry research and generalized
                  requirements. It is meant to serve as a starting point for
                  clients.{" "}
                </p>
                <p>
                  <strong>Personalized Assistance:</strong> Our application team
                  will provide personalized guidance to help clients tailor
                  their application documents to meet the specific requirements
                  of their chosen institutions.
                </p>
                <p>
                  <strong>Client Cooperation:</strong> Clients are required to
                  cooperate with our application team, provide accurate
                  information, and promptly respond to requests for documents
                  and information.
                </p>
                <p>
                  <strong>Document Review:</strong> Rise Higher Education will
                  review and verify documents submitted by clients for general
                  accuracy and completeness. However, we do not guarantee
                  admission outcomes.{" "}
                </p>
                <p>
                  <strong>Final Responsibility:</strong> Clients are responsible
                  for ensuring that their application documents comply with
                  university requirements and deadlines. Rise Higher Education
                  does not accept liability for rejected applications.
                </p>
                <p>
                  <strong>Application Outcomes:</strong> Admission decisions are
                  made by universities or colleges, and success is subject to
                  various factors, including client qualifications and
                  institution-specific policies.
                </p>
                <p>
                  <strong>Confidentiality:</strong> Rise Higher Education
                  respects client privacy and ensures the confidentiality of all
                  submitted documents and information. We emphasize the
                  importance of providing accurate, legible, and verifiable
                  documents. It is the student's responsibility to ensure that
                  the documents they submit meet the standards and requirements
                  of the institutions they are applying to
                </p>
                <p>
                  <strong>Liability:</strong> Rise Higher Education shall not be
                  held liable for any consequences resulting from incomplete,
                  inaccurate, or non-compliant application documents or
                  admission decisions.
                </p>
                <p>
                  By using Rise Higher Education's Documents Checklist service,
                  clients acknowledge and accept the terms and conditions
                  outlined in this disclaimer, including the pledge for
                  non-disclosure and non-misuse of documents without their
                  consent.
                </p>
                <p>
                  These terms and conditions may be subject to change without
                  prior notice. It is your responsibility to review them
                  periodically for any updates.
                </p>
                <p>
                  If you have any questions or concerns regarding the Portal,
                  please contact our support team at{" "}
                  <a href="mailto:contact@risehighereducation.com">
                    contact@risehighereducation.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
