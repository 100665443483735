import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import "../Assets/Styles/affiliate.css";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import country_codes from "../Assets/Data/countries_code.json";
import { validateAffiliate } from '../../utils/validation';

const AffiliateHome = () => {
  const [data, setData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    message: "",
    countryCode: "",
  });

  const navigate = useNavigate();

  const onChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[event.target.name];
      return newErrors;
    });
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = () => {

    const { isValid, errors } = validateAffiliate(data);

    //console.log('Validation Result:', { isValid, errors });

    if (!isValid) {
      setErrors(errors); // Set validation errors to state
      //setLoading(false);
      return; // Exit the function if form is not valid
    }

    API("post", "/affiliate/user/enroll", data)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
          button: "Ok!",
        });
        setData({
          fullName: "",
          email: "",
          mobile: "",
          message: "",
        });
        navigate("/affiliate-dashboard/" + res?.data?.data?._id);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let name = localStorage.getItem("name") ?? "";
    let email = localStorage.getItem("email") ?? "";
    let phone = localStorage.getItem("phone") ?? "";
    let countryCode = localStorage.getItem("countryCode") ?? "";
    setData({ ...data, fullName: name, email, mobile: phone, countryCode });
  }, []);

  return (
    <div className="">
      <h2>Fill the form below to enroll in our Affiliate Program</h2>
      <div className="row">
        <div className="col-md-6">
          <br />
          <div className="form-floating mb-3">
            <input
              className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
              placeholder=""
              name="fullName"
              value={data?.fullName}
              onChange={onChange}
            />
            <label for="floatingInput">
              Full Name<span className="required">*</span>
            </label>
            {errors.fullName && (
              <span className="required">{errors.fullName}</span>
            )}
          </div>
          <div className="form-floating mb-3">
            <input
              className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
              placeholder=""
              name="email"
              value={data?.email}
              onChange={onChange}
            />
            <label for="floatingInput">
              Email<span className="required">*</span>
            </label>
            {errors.email && (
              <span className="required">{errors.email}</span>
            )}
          </div>
          <div className="row">
            <div className="col-3">
              <div className="form-floating mb-3">
                <select
                  list="countryCodeData"
                  value={data.countryCode}
                  onChange={onChange}
                  placeholder="choose.."
                  className={`form-select ${errors.country_codes ? 'is-invalid' : ''}`}
                  name="countryCode"
                >
                  <option value={""}>Please select</option>
                  {country_codes.map((op, i) => (
                    <option value={op.dial_code}>
                      {op.dial_code + " " + op.name}
                    </option>
                  ))}
                </select>
                <label for="floatingTextarea">Country Code<span className="required">*</span></label>
                {errors.countryCode && (
                  <span className="required">{errors.countryCode}</span>
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                  placeholder=""
                  name="mobile"
                  value={data?.mobile}
                  onChange={onChange}
                />
                <label for="floatingInput">
                  Phone Number<span className="required">*</span>
                </label>
                {errors.mobile && (
                  <span className="required">{errors.mobile}</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-3">
            <textarea
              rows={4}
              className="form-control"
              placeholder=""
              name="message"
              value={data?.message}
              onChange={onChange}
            />
            <label for="floatingTextarea">Message (if any)</label>
          </div>
          <button
            className="form-button btn btn-primary-regular"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliateHome;
