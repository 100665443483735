var countriesData = [
  {
    Country: "United Kingdom",
    "Employment Rate": 5.25,
    "University Rankings": 13.70,
    "Tuition Fees": 11.46,
    "PR and Settlement Opportunities": 5.80,
    "Graduate Pay Scales": 5.42,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 7.42,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "85%(+1.5%)",
    "Data_University_Rankings": "10",
    "Data_Tuition_Fees": "£10,000 - £38,000/year",
    "Data_PR_and_Settlement_Opportunities": "4",
    "Data_Graduate_Pay_Scales": "£32,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "£15500",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business",
      "Medicine",
      "Law"
    ]
  },
  {
    Country: "United States of America",
    "Employment Rate": 5.31,
    "University Rankings": 36.99,
    "Tuition Fees": 14.10,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 7.33,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 7.54,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "86% (+2.1%)",
    "Data_University_Rankings": "27",
    "Data_Tuition_Fees": "$25,000 - $50,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "$55,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "$20,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business", "Engineering", "Computer Science"
    ]
  },
  {
    Country: "Canada",
    "Employment Rate": 5.37,
    "University Rankings": 4.11,
    "Tuition Fees": 7.55,
    "PR and Settlement Opportunities": 7.25,
    "Graduate Pay Scales": 4.87,
    "Spouse Work Rights": 7.35,
    "Cost of Living": 5.68,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "87 (+2%)",
    "Data_University_Rankings": "3",
    "Data_Tuition_Fees": "CAD 20,000 - CAD 35,000/year",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "CAD 50,000/year",
    "Data_Spouse_Work_Rights": "5",
    "Data_Cost_of_Living": "CAD 20635",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Engineering", "Healthcare", "IT"
    ]
  },
  {
    Country: "Australia",
    "Employment Rate": 5.19,
    "University Rankings": 9.59,
    "Tuition Fees": 8.55,
    "PR and Settlement Opportunities": 5.80,
    "Graduate Pay Scales": 4.33,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 5.15,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "84% (+1%)",
    "Data_University_Rankings": "7",
    "Data_Tuition_Fees": "AUD 25,000 - AUD 45,000/year",
    "Data_PR_and_Settlement_Opportunities": "4",
    "Data_Graduate_Pay_Scales": "AUD 50,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "AUD 21,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business", "IT", "Hospitality"
    ]
  },
  {
    Country: "New Zealand",
    "Employment Rate": 5.12,
    "University Rankings": 1.37,
    "Tuition Fees": 7.00,
    "PR and Settlement Opportunities": 7.25,
    "Graduate Pay Scales": 3.84,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 4.52,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "83% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "NZD 22,000 - NZD 40,000/year",
    "Data_PR_and_Settlement_Opportunities": "5",
    "Data_Graduate_Pay_Scales": "NZD 48,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "NZD 20,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Agriculture and Environmental Science",
      "Hospitality and Tourism Management",
      "Engineering"
    ]
  },
  {
    Country: "Ireland",
    "Employment Rate": 5.06,
    "University Rankings": 1.37,
    "Tuition Fees": 7.65,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 5.82,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 5.81,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "82% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "€12,000 - €25,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€36,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "€14,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Computer Science and Information Technology",
      "Business and Finance",
      "Medicine and Health Sciences"
    ]
  },
  {
    Country: "France",
    "Employment Rate": 5.00,
    "University Rankings": 6.85,
    "Tuition Fees": 0.87,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 5.13,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 4.98,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "81% (+1%)",
    "Data_University_Rankings": "5",
    "Data_Tuition_Fees": "€200 - €4,000/year (Public)",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€35,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "€12,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Culinary Arts and Hospitality Management",
      "Fashion and Luxury Management",
      "Aeronautics and Aerospace Engineering"
    ]
  },
  {
    Country: "Germany",
    "Employment Rate": 5.56,
    "University Rankings": 5.48,
    "Tuition Fees": 0.62,
    "PR and Settlement Opportunities": 5.80,
    "Graduate Pay Scales": 5.86,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 4.56,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "90% (+2%)",
    "Data_University_Rankings": "4",
    "Data_Tuition_Fees": "€0 - €3,000/year (Public)",
    "Data_PR_and_Settlement_Opportunities": "4",
    "Data_Graduate_Pay_Scales": "€40,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "€11,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Engineering and Technology",
      "Business Administration (MBA)",
      "Medicine and Healthcare"
    ]
  },
  {
    Country: "United Arab Emirates",
    "Employment Rate": 4.88,
    "University Rankings": 1.37,
    "Tuition Fees": 5.08,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 2.16,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 2.54,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "79% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "AED 30,000 - AED 70,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "AED 60,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "AED 25,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Business and Management",
      "Aviation and Aerospace Engineering",
      "Hospitality and Tourism Management"
    ]
  },
  {
    Country: "Singapore",
    "Employment Rate": 5.43,
    "University Rankings": 2.74,
    "Tuition Fees": 8.23,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 5.83,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 4.95,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "88% (+2%)",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "SGD 20,000 - SGD 40,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "SGD 60,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "SGD 18,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Engineering and Technology",
      "Business and Finance",
      "Biomedical Sciences"
    ]
  },
  {
    Country: "Finland",
    "Employment Rate": 4.94,
    "University Rankings": 1.37,
    "Tuition Fees": 4.96,
    "PR and Settlement Opportunities": 5.8,
    "Graduate Pay Scales": 5.43,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 4.98,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "80% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "€6,000 - €18,000/year",
    "Data_PR_and_Settlement_Opportunities": "4",
    "Data_Graduate_Pay_Scales": "€37,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "€12,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Environmental Sciences and Sustainability",
      "Education",
      "Design and Creative Arts"
    ]
  },
  {
    Country: "Sweden",
    "Employment Rate": 5.19,
    "University Rankings": 2.74,
    "Tuition Fees": 3.72,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 0.54,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 3.73,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "84% (+1.5%)",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "SEK 80,000 - SEK 140,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "SEK 45,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "SEK 110,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Computer Science and Software Engineering",
      "Social Sciences and Sustainability",
      "Medicine and Public Health"
    ]
  },
  {
    Country: "Denmark",
    "Employment Rate": 5.06,
    "University Rankings": 1.37,
    "Tuition Fees": 6.00,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 6.15,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 5.81,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "82% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "€8,000 - €21,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€42,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "€14,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Design and Architecture",
      "Renewable Energy and Environmental Engineering",
      "Social Sciences and Economics"
    ]
  },
  {
    Country: "Italy",
    "Employment Rate": 4.81,
    "University Rankings": 2.74,
    "Tuition Fees": 0.72,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 4.84,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 4.98,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "78% (+1%)",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "€500 - €3,000/year (Public)",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€33,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "€12,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Art and Design",
      "Culinary Arts and Gastronomy",
      "Architecture and Urban Planning"
    ]
  },
  {
    Country: "India",
    "Employment Rate": 4.63,
    "University Rankings": 4.11,
    "Tuition Fees": 0.85,
    "PR and Settlement Opportunities": 3,
    "Graduate Pay Scales": 1.12,
    "Spouse Work Rights": 2.94,
    "Cost of Living": 1.81,
    "Cultural Fit": 3.80,
    "Safety and Security": 3.41,
    "Quality of Life": 3.53,
    "Data_Employment_Rate": "75% (+1%)",
    "Data_University_Rankings": "3",
    "Data_Tuition_Fees": "₹75,000 - ₹300,000/year",
    "Data_PR_and_Settlement_Opportunities": "2",
    "Data_Graduate_Pay_Scales": "₹700,000/year",
    "Data_Spouse_Work_Rights": "2",
    "Data_Cost_of_Living": "₹400,000/year",
    "Data_Cultural_Fit": "3",
    "Data_Safety_and_Security": "3",
    "Data_Quality_of_Life": "3",
    "Top_Programs": [
      "Information Technology and Computer Science",
      "Business and Management",
      "Medicine and Healthcare"
    ]
  },
  {
    Country: "Spain",
    "Employment Rate": 4.69,
    "University Rankings": 2.74,
    "Tuition Fees": 0.88,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 4.40,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 4.56,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "76% (+1%)",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "€750 - €3,500/year (Public)",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€30,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "€11,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Tourism and Hospitality Management",
      "Language and Culture Studies",
      "Renewable Energy and Sustainability"
    ]
  },
  {
    Country: "Hungary",
    "Employment Rate": 4.57,
    "University Rankings": 1.37,
    "Tuition Fees": 1.55,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 3.52,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 3.73,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "74% (+1%)",
    "Data_University_Rankings": "1",
    "Data_Tuition_Fees": "€2,500 - €5,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€24,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "€9,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Medical and Health Sciences",
      "Engineering and Technology",
      "Business Administration and Economics"
    ]
  },
  {
    Country: "Netherlands",
    "Employment Rate": 5.43,
    "University Rankings": 0,
    "Tuition Fees": 4.34,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 5.57,
    "Spouse Work Rights": 5.88,
    "Cost of Living": 5.81,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "88% (+2%)",
    "Data_University_Rankings": "0",
    "Data_Tuition_Fees": "€6,000 - €15,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "€38,000/year",
    "Data_Spouse_Work_Rights": "4",
    "Data_Cost_of_Living": "€14,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Engineering and Technology",
      "International Business and Economics",
      "Social Sciences and Political Science"
    ]
  },
  {
    Country: "Switzerland",
    "Employment Rate": 5.37,
    "University Rankings": 2.74,
    "Tuition Fees": 1.03,
    "PR and Settlement Opportunities": 4.35,
    "Graduate Pay Scales": 11.00,
    "Spouse Work Rights": 4.41,
    "Cost of Living": 9.95,
    "Cultural Fit": 5.06,
    "Safety and Security": 5.68,
    "Quality of Life": 5.88,
    "Data_Employment_Rate": "87% (+2%)",
    "Data_University_Rankings": "2",
    "Data_Tuition_Fees": "CHF 1,000 - CHF 4,000/year",
    "Data_PR_and_Settlement_Opportunities": "3",
    "Data_Graduate_Pay_Scales": "CHF 75,000/year",
    "Data_Spouse_Work_Rights": "3",
    "Data_Cost_of_Living": "CHF 24,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "5",
    "Data_Quality_of_Life": "5",
    "Top_Programs": [
      "Hospitality and Hotel Management",
      "Finance and Banking",
      "International Relations and Diplomacy"
    ]
  },
  {
    Country: "Cyprus",
    "Employment Rate": 4.32,
    "University Rankings": 0,
    "Tuition Fees": 2.07,
    "PR and Settlement Opportunities": 2.90,
    "Graduate Pay Scales": 3.23,
    "Spouse Work Rights": 2.94,
    "Cost of Living": 3.32,
    "Cultural Fit": 5.06,
    "Safety and Security": 4.55,
    "Quality of Life": 4.71,
    "Data_Employment_Rate": "70% (+1%)",
    "Data_University_Rankings": "0",
    "Data_Tuition_Fees": "€3,000 - €7,000/year",
    "Data_PR_and_Settlement_Opportunities": "2",
    "Data_Graduate_Pay_Scales": "€22,000/year",
    "Data_Spouse_Work_Rights": "2",
    "Data_Cost_of_Living": "€8,000/year",
    "Data_Cultural_Fit": "4",
    "Data_Safety_and_Security": "4",
    "Data_Quality_of_Life": "4",
    "Top_Programs": [
      "Marine Sciences and Oceanography",
      "Hospitality and Tourism Management",
      "Digital Marketing and E-commerce"
    ]
  },
];

export { countriesData };
