import React, { useEffect, useState } from "react";
import "../Assets/Styles/userDashboard.css";
import { url } from "../Constants";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import CredentialModal from "../Dashboard/User Application Profile/CredentialModa";
import API from "../../Api/Api";
import moment from "moment";

const ApplicationDashboard = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [jobQueryModal, setJobQueryModal] = useState({
    state: false,
    id: null,
  });
  const getData = async () => {
    const response = await fetch(`${url}/application/`, {
      method: "GET",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const res_json = await response.json();
    setData(res_json.data);

    //console.log(getData);
  };
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    url: "",
    modal: false,
  });

  const handleMarkComplete = async (step) => {
    try {
      //console.log(`Marking step ${step} as complete`);
      const response = await fetch(`${url}/user/updateCompletionSteps`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({ [`step${step}Complete`]: true }),
      });

      const res_json = await response.json();

      //console.log("API Response:", res_json);

      if (res_json.success) {
        swal({
          title: "Success!",
          text: "Step marked as complete!",
          icon: "success",
          button: "Ok!",
        });
        navigate('/userdashboard');
        getData(); // Refresh the application data
      } else {
        swal({
          title: "Error!",
          text: res_json.message,
          icon: "error",
          button: "Ok!",
        });
      }
    } catch (error) {
      console.error("Error marking step as complete:", error);
    }

    try {
      // Assuming you're fetching or have access to user data
      const firstName = localStorage.getItem('firstName') || 'User';
      const email = localStorage.getItem('email');
      const stepCompleted = step; // Replace with the actual step number if dynamic
    
      // Validate required data
      if (!email) {
        console.error('Email is missing');
        return;
      }
    
      // Construct the `userData` object
      const userData = {
        stepCompleted,
        firstName,
        email,
      };
    
      const response = await fetch(`${url}/user/send-completion-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'), // Assuming token is stored in localStorage
        },
        body: JSON.stringify(userData),
      });
    
      const res_json = await response.json();
    
      if (res_json.success) {
        //console.log('Email sent successfully');
      } else {
        console.error('Failed to send email', res_json.message);
      }
    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  const sendMail = async (id, msg) => {
    try {
      await API("post", `${url}/application/msg`, {
        id: id,
        msg: msg,
      });
      swal({
        title: "Success",
        text: "Query Submitted",
        icon: "success",
        button: "Ok",
      });
      setJobQueryModal({ state: false, id: null });
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCloseCredModal = () => {
    setCredentials({
      ...credentials,
      modal: false,
    });
  };

  return (
    <>
      <div className="body-content no-filterpanel">
        <div className="d-flex justify-content-between">
          <div>
            <h1 className="mb-3">Application Dashboard</h1>
          </div>
          <div className="me-4">
          <Button
              variant="primary-regular blinking-btn"
              onClick={() => handleMarkComplete(7)} // Replace 5 with the appropriate step number
              className="ms-2"
            >
              Mark Step as Complete
            </Button>
            <button
              className="widget-cta-page-nopos float-start margin-b3"
              onClick={() => {
                navigate("/userdashboard");
              }}
            >
              <i className="fa fa-home"></i> Back to Dashboard
            </button>
          </div>
        </div>
        <h6>
          Stay updated on your applications with real-time status tracking.
        </h6>
        <p>
          We follow up with institutions weekly, however if you receive any
          updates, please share them with us on{" "}
          <a href="mailto:applications@risehighereducation.com">
            applications@risehighereducation.com
          </a>
        </p>
        <div className="body-panel">
          <div className="body-container dashboard grid-frame">
            <div className="row">
              <div className="col">
                <table className="table table-striped table-hover applicationDashboard">
                  <thead>
                    <tr>
                      <th scope="col">Application ID</th>
                      <th scope="col">Course Name</th>
                      <th scope="col">University/College</th>
                      <th scope="col">Intake</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col">Post a Query</th>
                      <th scope="col">Credentials</th>
                      <th scope="col">Notes</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr>
                        <td>{item.applicationId}</td>
                        <td>{item.courseName}</td>
                        <td>
                          {item.universityName} <br />
                          <br />
                          <div>
                            {item?.credentials?.map((itm) => (
                              <div key={itm?._id} className="d-flex">
                                <p>
                                  <b>Email</b> : {itm?.email}
                                </p>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <p>
                                  <b>Password</b> : {itm?.password}
                                </p>
                                &nbsp;&nbsp;&nbsp;
                                <p>
                                  <b>URL</b> : <a href={itm?.url}>{itm?.url}</a>
                                </p>
                                &nbsp; &nbsp;
                                {/* <MdEdit
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setCredentials({
                                          modal: true,
                                          email: itm?.email,
                                          password: itm?.password,
                                          url: itm?.url,
                                          id: itm?._id,
                                        })
                                      }
                                    /> */}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>{item.intake}</td>
                        <td>{item.country}</td>
                        <td>
                          {item.applicationStatus} <br />
                          {item?.acceptance_deadline && (
                            <span className="badge bg-secondary">
                              Deadline:{" "}
                              {moment
                                .utc(item?.acceptance_deadline)
                                .format("MM-DD-YYYY")}
                            </span>
                          )}{" "}
                        </td>
                        <td>
                          <button className="btn-nostyle">
                            <i
                              className="fa fa-envelope"
                              onClick={() => {
                                setJobQueryModal({
                                  state: true,
                                  id: item._id,
                                });
                                // swal({
                                //   text: "Enter your query:",
                                //   content: {
                                //     element: "input",
                                //     attributes: {
                                //       placeholder: "",
                                //     },
                                //   },
                                //   buttons: {
                                //     // cancel: true,
                                //     confirm: "Submit",
                                //   },
                                // }).then((value) => {
                                //   if (value) {
                                //     sendMail(item._id, value);
                                //   }
                                // });
                              }}
                            ></i>
                          </button>{" "}
                        </td>
                        <td>
                          {" "}
                          <button
                            onClick={() => {
                              setCredentials({
                                email: item?.credential?.email,
                                password: item?.credential?.password,
                                url: item?.credential?.url,
                                modal: true,
                                id: item?._id,
                              });
                            }}
                            className="btn btn-sm text-start"
                          >
                            Add Credentials
                          </button>{" "}
                        </td>
                        <td>{item.notes}</td>
                        <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button
              className="widget-cta-page-nopos float-end mt-3"
              onClick={() => {
                navigate("/scholarship");
              }}
            >
              Proceed to Scholarship
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={jobQueryModal.state}
        onHide={() => setJobQueryModal({ state: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Post your Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            type="text"
            placeholder="Post your query"
            value={jobQueryModal.value}
            onChange={(e) =>
              setJobQueryModal({ ...jobQueryModal, value: e.target.value })
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary-regular"
            onClick={() => sendMail(jobQueryModal.id, jobQueryModal.value)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={credentials.modal} onHide={handleCloseCredModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CredentialModal
            email={credentials.email}
            url={credentials.url}
            password={credentials.password}
            id={credentials.id}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCredModal}>
            Close
          </Button>
          {/* <Button variant="primary">
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ApplicationDashboard;
