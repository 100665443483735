import React, { useEffect, useState } from 'react'
import { useAudio } from '../../Context/AudioContext'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import swal from 'sweetalert'

const CountryCard = (props) => {
  const { thumbData, setThumbData, maxLimit } = useAudio()

  const handleChnage = (e) => {
    let checked = e.target.checked
    if (checked && thumbData?.length < maxLimit) {
      setThumbData((prev) => [...prev, props.data])
    } else {
      if (checked) {
        swal({
          title: 'Error!',
          text: 'You can only select 3 countries!',
          icon: 'error',
          button: 'Ok',
        })
      }
      setThumbData((prev) =>
        prev.filter((item) => item.name !== props.data.name),
      )
    }
  }

  return (
    <div className="timeline-carousel__item">
      <div className="timeline-carousel__image">
        <div
          className="media-wrapper media-wrapper--overlay"
          style={{
            background: `url(${props.data.CountryFlag}) center center`,
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      <div className="timeline-carousel__item-inner">
        <span className="year">{props.data.name}</span>
        <span className="country-info">
          <ul>
            <li>
              <strong>{props.data.tagLine}</strong>
            </li>
            <li>
              <i className="fas fa-money-bill-wave fa-xl" />{' '}
              {props.data.currency}
            </li>
            <li>
            <p>
              {props.data.name === "India" ? (
                <>
                <strong>1113</strong> (<strong>56</strong> central, <strong>478</strong> state, <strong>124</strong> deemed, <strong>455 </strong>private, and <strong>159</strong> institutions of national importance in India).
                </>
              ) : (
                <>
                {Number(props.data?.public_universities) + Number(props.data?.private_universities)} ( {props.data?.public_universities} Public &amp;{' '}
                {props.data?.private_universities} Private Universities)
                </>
              )}
              </p>
            </li>
            <li>
              {props.data.name === "United States of America" ? (
                <>
                  13 universities consistently ranked among the world's top 20 prestigious league
                </>
              ) : (
                <>
                  <strong>{props.data.rankedt100}</strong> universities ranked in top 100 and{' '}
                  <strong>{props.data.rankedt500}</strong> universities ranked in top 500.
                </>
              )}
            </li>
            <li>
              <strong>International Students:</strong>{' '}
              {props.data.international_students}
            </li>
            <li>
              <strong>Graduate Employment Rate: </strong>{props.data.employment_rate}
            </li>
            {/* <li>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant="determinate"
                  value={props.data?.employment_rate}
                  style={{ color: '#dc7215' }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize={11}
                  >
                    {`${Math.round(props.data.employment_rate)}%`}
                  </Typography>
                </Box>
              </Box>
            </li> */}
            <span className="country-selection">
              <input
                type="checkbox"
                defaultValue=""
                checked={thumbData?.includes(props.data)}
                onChange={handleChnage}
              />
            </span>
          </ul>
        </span>
      </div>
    </div>
  )
}

export default CountryCard
